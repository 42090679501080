import React from 'react';
import './Articles.css';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CICD from "../../assets/cicd.png";
import GithubMetrics from "../../assets/githubmetrics.png";
import Medium from "../../assets/medium-icon.svg";

const Articles = () => {
    return(
        <>
            <Header/>
            <div className='bannerContainer'>
                    <img className='banner' src={CICD} alt="cicd banner" onClick={() => window.open('https://medium.com/@gustavolirasn/ci-cd-com-github-actions-b4c10de95311')}/>
            </div>
            <div className='main-container'>
                <div className='article1'>
                    <p className='articleTitle'>CI/CD with GitHub Actions - Portuguese</p>
                    <p className='articleText'>Automate your software development workflow efficiently using GitHub Actions for continuous integration and continuous deployment.</p>
                    <p className='slogan'>Ideal for newcomers</p>
                    <img className="mediumLogo" src={Medium} alt='medium logo' onClick={() => window.open('https://medium.com/@gustavolirasn/ci-cd-com-github-actions-b4c10de95311')}/>
            </div>
            <div className='bannerContainer1'>
                <img className='banner' src={GithubMetrics} alt="cicd banner" onClick={() => window.open('https://medium.com/@gustavolirasn/ci-cd-com-github-actions-b4c10de95311')}/>
            </div>
            <div className='article1'>
                <p className='articleTitle'>An Approach Based on Repository Metrics - Portuguese</p>
                <p className='articleText'>Quantitative and Qualitative Analysis of Software Development</p>
                <p className='slogan'>Written for the curious</p>
                <img className="mediumLogo" src={Medium} alt='medium logo' onClick={() => window.open('https://medium.com/@gustavolirasn/an%C3%A1lise-quantitativa-e-qualitativa-de-desenvolvimento-de-software-uma-abordagem-baseada-em-c709aa1b9355')}/>
            </div>
            </div>
            <Footer/>
        </>
    )
}

export default Articles;