import React, { useEffect, useState } from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import './App.css';
import Background from "./assets/Background.svg"
import Clothes from "./assets/Clothes.svg"
import Ears from "./assets/Ears.svg"
import Eyebrows from "./assets/Eyebrows.svg"
import Eyes from "./assets/Eyes.svg"
import Face from "./assets/Face.svg"
import Glasses from "./assets/Glasses.svg"
import Hair from "./assets/Hair.svg"
import Mouth from "./assets/Mouth.svg"
import Neck from "./assets/Neck.svg"
import Nose from "./assets/Nose.svg"
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CircledMouse from './components/CircledMouse/CircledMouse';
import {ContainerRow} from './App.styles';

function App() {
  const [titleIndex, setTitleIndex] = useState(0);
  const titles = ["Fullstack Developer", "Blockchain Engineer", "Manager"];

  useEffect(() => {
    const interval = setInterval(() => {
      setTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [titles.length]);

  return (
    <div className="mainContainer">
      <CircledMouse/>
      <Header/>
      <div className='midContainer'>
        <div className='characterContainer'>
          <img id="Background" src={Background} alt="Background" className='character'/>
          <img id="Clothes" src={Clothes} alt="Clothes" className='character'/>
          <img id="Ears" src={Ears} alt="Ears" className='character'/>
          <img id="Eyebrows" src={Eyebrows} alt="Eyebrows" className='character'/>
          <img id="Face" src={Face} alt="Face" className='character'/>
          <img id="Glasses" src={Glasses} alt="Glasses" className='character'/>
          <img id="Hair" src={Hair} alt="Hair" className='character'/>
          <img id="Mouth" src={Mouth} alt="Mouth" className='character'/>
          <img id="Neck" src={Neck} alt="Neck" className='character'/>
          <img id="Nose" src={Nose} alt="Nose" className='character'/>
        </div>
        <div className='midTextContainer'>
          <div>
            <p className='greet'>Hi, I'm Gustavo ✨</p>
            <p>A {titles[titleIndex] === "Fullstack Developer" && "FE-Heavy"} <strong className='codeText'>{`<${titles[titleIndex]} />`}</strong></p>
            <p>Who writes clean, scalable and efficient code<strong className='codeText'>.</strong></p>
          </div>

          <div className='midSmallContainer'>
            <strong>Proven success crafting products for global clients.</strong><br/><br/>
            <strong>Expertise in leading efficient teams.</strong>
          </div>
        </div>
      </div>
      <div className='thirdContainer'>
        <ContainerRow>
          <div className='skillsContainer'>
            <p className='greet'>My Skills</p>
            <div className='skillBlockContainer'>
              <div className='row'>
                <div className='skillBlock'>
                  <strong>HTML/CSS</strong>
                </div>
                <div className='skillBlock'>
                  <strong>Javascript</strong>
                </div>
                <div className='skillBlock'>
                  <strong>Typescript</strong>
                </div>
              </div>
              <div className='row'>
                <div className='skillBlock'>
                  <strong>React</strong>
                </div>
                <div className='skillBlock'>
                  <strong>GIT</strong>
                </div>
                <div className='skillBlock'>
                  <strong>Node</strong>
                </div>
              </div>
              <div className='row'>
                <div className='skillBlock'>
                  <strong>Python</strong>
                </div>
                <div className='skillBlock'>
                  <strong>Swift</strong>
                </div>
                <div className='skillBlock'>
                  <strong>Solidity</strong>
                </div>
              </div>
            </div>
          </div>
        </ContainerRow>
        <ContainerRow>
        <div className='knowMeContainer'>
          <p className='greet'>Get to know me!</p>
          <p>
          I'm Gustavo Lira Santos, a <strong className='knowMeBold'>Frontend Engineer</strong> with a passion for pushing <br/>
          the boundaries of web development, from traditional projects to cutting-edge <br/>
          <strong className='knowMeBold'>Web3</strong> technologies. I thrive on building and managing the frontend of websites<br/>
          and web applications, ensuring they not only meet but <strong className='knowMeBold'>exceed expectations</strong>, <br/>
          ultimately contributing to the success of the overall product<strong className='knowMeBold'>.</strong>
          </p>
          <br/>
          <GitHubIcon style={{cursor: 'pointer', marginTop: '10px'}} onClick={() => window.open("https://github.com/GustavoLSantos")}/>
          </div>
        </ContainerRow>
      </div>

      <div className='finalContainer'>
        <div>
        <p>
          Over the <strong>past decade</strong>, I've honed my skills in various domains, from <strong>software development</strong> to <strong>team leadership</strong>,<br/>
          <strong>API integration</strong>, and <strong>DevOps practices</strong>. Proficient in a wide array of technologies including <strong>React.JS, Python/C#,<br/>
          and Solidity for Web3 DApps</strong>, I bring a wealth of expertise to any project.
          </p>
          <br/>
          <p>
          With a background in <strong>Computer Science and System Development</strong>, coupled with fluency in <strong>English and Portuguese</strong>,<br/>
          I am well-equipped to tackle any challenge that comes my way. I'm seeking an opportunity to contribute, learn, and grow.
          </p>
          <br/>
          <strong>Do you have one of those? Or just want a quick chat?</strong><br/>
          <button className='contact' onClick={() => window.open("https://www.linkedin.com/in/gustavolirasn/")}>Contact me here</button>
        </div>
      </div>
     <Footer/>
    </div>
  );
}

function EyeTracking() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const calculateEyePosition = () => {
    const eyeCenterX = 365;
    const eyeCenterY = 315; 
    const maxOffset = 5; 

    const diffX = mousePosition.x - eyeCenterX;
    const diffY = mousePosition.y - eyeCenterY;

    let newX = eyeCenterX;
    let newY = eyeCenterY;

    if (Math.abs(diffX) > maxOffset) {
      newX += maxOffset * Math.sign(diffX);
    } else {
      newX = mousePosition.x;
    }

    if (Math.abs(diffY) > maxOffset) {
      newY += maxOffset * Math.sign(diffY);
    } else {
      newY = mousePosition.y;
    }

    return { x: newX, y: newY };
  };

  const eyePosition = calculateEyePosition();

  return (
    <img id="Eyes" src={Eyes} alt="Eyes" style={{ top: eyePosition.y, left: eyePosition.x }} className='character'/>
  );
}

export default function EyeTrackingApp() {
  return (
    <div className="mainContainer">
      <App />
      <EyeTracking />
    </div>
  );
}
