import React from 'react';
import './Header.css';
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    return(
        <div className='header'>
            <div className='headerContainer'>
                <p className='logo' onClick={() => navigate("/")}>Gusta</p>
                <p className='redirectText' onClick={() => navigate("/projects")}>Projects</p>
                <p className='redirectText' onClick={() => navigate("/articles")}>Articles</p>
            </div>
            <div className='contactContainer' onClick={() => window.open("https://www.linkedin.com/in/gustavolirasn/")}>
                <p className='contactText'>Contact Me</p>
            </div>
        </div>
    )
}

export default Header;