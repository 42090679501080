import React from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import './Footer.css';

const Footer = () => {
    return(
        <div className='footer'>
            <div className='contactContainer' >
                <InstagramIcon onClick={() => window.open("https://www.instagram.com/gu5ta.jpg/")}/>
                <LinkedInIcon onClick={() => window.open("https://www.linkedin.com/in/gustavolirasn/")}/>
                <GitHubIcon onClick={() => window.open("https://github.com/GustavoLSantos")}/>
            </div>
            <div className='contactContainer' onClick={() => window.open("https://www.linkedin.com/in/gustavolirasn/")}>
                <ChatBubbleOutlineIcon/>
                <p className='contactText'>Contact Me</p>
            </div>
        </div>
    )
}

export default Footer;